import React, { useState, useCallback, useRef, useEffect } from "react";
import IconDownload from '../../../img/icons/symbols/expediente.png';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Sort,
  PageSettingsModel
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

export interface IHealthcareDocumentProp {
  documents?: any[] | undefined;
  images?: any[] | undefined;
  handleRequestDocument: (documentID: string) => void;
}

const HealthcareDocumentBlock: React.FC<IHealthcareDocumentProp> = ({
  documents = [],
  handleRequestDocument
}) => {
  let tooltip: any;

  const beforeRender = (args: any) => {
    const targetColumn = args.target.closest("td")?.getAttribute("aria-colindex");
    if (targetColumn === "1") {
      tooltip.content = "Descargar";
    } else {
      tooltip.content = args.target.closest("td")?.innerText || "";
    }
    tooltip.tipPointerPosition = "Middle";
    tooltip.position = "TopLeft";
  };

  const [pageOptions] = useState<PageSettingsModel>({
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  });

  const [sortingOptions] = useState<Object>({
    columns: [{ field: "fecha", direction: "Descending" }],
  });

  const gridTemplate = useCallback((props: any) => {
    if (!props) return null; 

    if (props.requiereOtp === false) {
      return (
        <div className="link-document">
          <a href={props.uri} rel={props.nombre} target="_blank" className="link-bold">
            <img style={{ width: '20px', height: '20px' }} src={IconDownload} alt="descargar" />
          </a>
        </div>
      );
    } else {
      return (
        <div className="link-document">
          <a
            rel={props.nombre}
            className="link-bold"
            onClick={(e) => {
              e.preventDefault();
              handleRequestDocument(props.id);
            }}
          >
            <img style={{ width: '20px', height: '20px' }} src={IconDownload} alt="descargar" />
          </a>
        </div>
      );
    }
  }, [handleRequestDocument]);


  const gridRef = useRef<any>(null);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
  }, [documents]);

  return (
    <div>
      <TooltipComponent ref={(t: any) => (tooltip = t)} target=".e-rowcell" beforeRender={beforeRender}>
        <GridComponent
          ref={gridRef}
          dataSource={documents}
          allowPaging={documents.length >= 5}
          pageSettings={pageOptions}
          sortSettings={sortingOptions}
          enableHover={false}
          allowSelection={false}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="uri"
              headerText=""
              template={(props) => gridTemplate(props)}
              width="30"
            />
            <ColumnDirective field="nombre" headerText="Nombre" width="170" />
            <ColumnDirective
              field="fecha"
              headerText="Fecha de subida"
              type="date"
              format="dd/MM/yyyy"
              width="50"
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort]} />
        </GridComponent>
      </TooltipComponent>
    </div>
  );
};

export default HealthcareDocumentBlock;
