import React, { useState, useEffect } from "react";
import PageMenu from "../../blocks/PageMenu/PageMenu";
import Modal from "../../blocks/Modal/Modal";
import FormCreateCommunications from "../../blocks/Forms/FormCreateCommunication";
import CommunicationsBlock from "../../blocks/CommunicationOpening/CommunicationOpening";
import CommunicationsGrid from "components/blocks/Grids/CommunicationsGrid";
import { TextMessagesTemplates } from "../../blocks/TextMessagesTemplates/TextMessagesTemplates";
import useDecode from "hooks/useDecode";
import useGetAllUserCommunicationByUserId from "../../../services/communications/useGetAllUserCommunicationByUserId";
import useCreateUserCommunicationAppointment from "../../../services/communications/useCreateUserCommunicationAppointment";
import useModal from "../../../hooks/useModal";
import useCreateGeneralCommunication from "services/communications/useCreateGeneralCommunication";
import CommunicationsGridGeneral from "components/blocks/Grids/CommunicationsGridGeneral";
import useGetRole from "hooks/useGetRole";

interface IvaluesFormCreateCommunication {
  subject: string;
  comment: string;
  "loadfiles[]": any[];
}
interface IdataComunication {
  asunto: string;
  mensaje: string;
  userId: string;
  externalUserId: string | null;
  prestacionId: string;
  files: any[];
  comunicacionReferenciaId: string | null,
}

const Communications: React.FC = (props: any) => {

  const {
    getAllUserCommunication,
    allUserCommunication,
    loadingGetAllUserCommunication,
    errorGetAllUserCommunication,
    isComunicacionHabilitada,
  } = useGetAllUserCommunicationByUserId();
  // const {
  //   createUserCommunicationAppointment,
  //   successCreateUserCommunicationAppointment,
  //   loadingCreateUserCommunicationAppointment,
  //   errorCreateUserCommunicationAppointment,
  // } = useCreateUserCommunicationAppointment();
  const {
    createGeneralCommunication,
    loadingCreateGeneralCommunication,
    successCreateGeneralCommunication,
    errorCreateGeneralCommunication,
  } = useCreateGeneralCommunication();
  const { collapsed, handleCloseModal, handleOpenModal } = useModal();
  const [isLoading, setIsLoading] = useState(true);
  const tokenResponse = useDecode("token");
  const userId = tokenResponse && tokenResponse.extension_id;
  const externalUserId = tokenResponse?.extension_external_id;
  const { isImpersonation } = useGetRole;
  useEffect(() => {
    getAllUserCommunication(userId);
  }, [successCreateGeneralCommunication]);
  useEffect(() => {
    if (allUserCommunication.length === 0 || loadingGetAllUserCommunication) {
      setIsLoading(false);
    }
  }, [allUserCommunication]);

  const handleRedirect = (id: string) => {
    props.history.push({
      pathname: `/comunicaciones/${id}`,
    });
  };
  // const onSubmitCommunication = (values: IvaluesFormCreateCommunication) => {
  //   const formData = new FormData();
  //   formData.append("subject", values.subject);
  //   formData.append("comment", values.comment);
  //   formData.append("appointment_id", "");
  //   formData.append("record_id", "");
  //   formData.append("type", "0");
  //   formData.append("status", "0");
  //   for (let i = 0; i < values["loadfiles[]"].length; i++) {
  //     formData.append("files[]", values["loadfiles[]"][i]);
  //   }
  //   //send communication
  //   //redirect
  //   // createUserCommunication( id ,formData, handleRedirect);
  //   handleCloseModal();
  // };
  const convertBase64 = async (files: any) => {

    var arrayAuxiliar: any = [];
    Array.from(files && files).forEach((file: any) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        let prevExtension = file.name.split(".");
        var base64I = reader.result as string;
        arrayAuxiliar.push({
          // id: "",
          name: file.name,
          extension: prevExtension.pop(),
          externalUri: "",
          base64: base64I.split(",")[1],
          externalId: "",
        });
      };
    });
    return await arrayAuxiliar;
  };
  const onSubmitCommunication = async (data: any) => {
    const dataFiles = await convertBase64(data.files);
    setTimeout(() => {
      const newDataJsonRecord: IdataComunication = {
        asunto: data.asunto,
        mensaje: data.mensaje,
        userId: userId,
        externalUserId: externalUserId,
        prestacionId: null,
        files: dataFiles,
        comunicacionReferenciaId: null,
      };


      createGeneralCommunication(userId, newDataJsonRecord);
      getAllUserCommunication(userId);

      handleCloseModal();
    }, 700);
  };

  return (
    <>
      {!isImpersonation() &&
        <PageMenu title="Comunicaciones">
          <div className="btns-header-container">
            {isComunicacionHabilitada && (
              <button className="btn-text btn-gray" onClick={handleOpenModal}>
                <span className="e-icons e-add-new"></span>
                <span>Crear comunicación</span>
              </button>
            )}
          </div>
        </PageMenu>
      }
      <>
        {errorGetAllUserCommunication ? (
          <section className="container">
            <p className="loader-text">
              Lo sentimos, se ha producido un error.
            </p>
          </section>
        ) : (
          <>
            {(isLoading || loadingGetAllUserCommunication) &&
              !errorGetAllUserCommunication ? (
              <section>{TextMessagesTemplates.loading}</section>
            ) : (
              <div className="container">
                {allUserCommunication && allUserCommunication.length === 0 
                   ? (
                  <div className="row">
                    <div className="col-md-4 offset-md-4">
                      <section>
                        {/* <CommunicationsBlock
                          handleOpenModal={handleOpenModal}
                          handleCloseModal={handleCloseModal}
                          collapsed={collapsed}
                        /> */}
                        <p className="loader-text">
                          No hay comunicaciones para mostrar.
                        </p>
                      </section>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <section className="communications__grid">
                        <CommunicationsGridGeneral
                          arrData={allUserCommunication}
                          props={props}
                          isLoading={loadingGetAllUserCommunication}
                        />
                      </section>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </>
      <Modal collapsed={collapsed} onClose={handleCloseModal}>
        <>
          {!collapsed && !isImpersonation() ? (
            <FormCreateCommunications
              title={"Crear comunicación"}
              onSubmit={onSubmitCommunication}
              onClose={handleCloseModal}
            />
          ) : (
            <div className="ghost-form"></div>
          )}
        </>
      </Modal>
    </>
  );
};

export default Communications;
