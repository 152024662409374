import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import PageMenu from "../../blocks/PageMenu/PageMenu";
// import CardLinkSimple from "components/blocks/CardsLink/CardLinkSimple";
import CardLinkIcon from "../../blocks/CardsLink/CardLinkIcon";
import Modal from "../../blocks/Modal/Modal";
import CommunicationsBlock from "../../blocks/CommunicationOpening/CommunicationOpening";
import CommunicationsGrid from "../../blocks/Grids/CommunicationsGrid";
import FormCreateCommunications from "../../blocks/Forms/FormCreateCommunication";
import { TextMessagesTemplates } from "../../blocks/TextMessagesTemplates/TextMessagesTemplates";
import useCreateUserCommunicationRecord from "../../../services/communications/useCreateUserCommunicationRecord";
import useGetUserChatCommunicationByIdRecord from "../../../services/communications/useGetUserChatCommunicationByIdRecord";
import useGetCompleteRecord from "../../../services/recordSheets/useGetCompleteRecord";
import useGetAllHealthcareInformation from "../../../services/recordSheets/useGetAllHealthcareInformation";
import useGetAllTransactionAvailableByContingencyType from "services/transaction/useGetAllTransactionAvailableByContingencyType";
import useModal from "../../../hooks/useModal";
import useCheckTypes from "../../../hooks/useCheckRecordStatusAndTypes";
import IconArrowGray from "../../../img/icons/svg/botones/flecha-big-gris.svg";
import IMGcheck from "../../../img/07.png";
import "./RecordDetail.scss";
import useGetAllHealthcareDocument from "services/record/useGetAllHealthcareDocument";
import useGetAllNonAssistanceDocuments from "services/record/useGetAllNonAssistanceDocuments";
import FormValidateOTP from "components/blocks/Forms/FormValidateOTP";
import HealthcareDocumentsGrid from "components/blocks/Grids/HealthcareDocumentsGrid";
import useGetRequestDocumentOtp from "services/record/useGetRequestDocumentOtp";
import useModals2 from "hooks/useModal2";
import LoaderMin from "components/blocks/Loader/LoaderMin";
import RecordDetailManagementGrid from "components/blocks/Grids/RecordDetailManagementGrid";
import useGetAllAdministrationRecord from "services/recordSheets/useGetAllAdministrationRecord";
import useGetAllEconomicRecord from "services/recordSheets/useGetAllEconomicRecord";
import useDecode from "hooks/useDecode";
// import { useGeneralManagementState } from "../../pages/GeneralManagement/generalManagementState";
// import { SignalRContext } from "../../../contexts/SignalRContext";
import useGetRole from "hooks/useGetRole";
import NoData from "../noData/NoData";
import RoyalDecreeFormsGrid from "components/blocks/Grids/RoyalDecreeFormsGrid";
import useGetRoyalDecreeForms from "hooks/useGetRoyalDecreeForms";
interface IvaluesFormReplyCommunication {
  subject: string;
  message: string;
  "loadfiles[]": any[];
}
interface IdataComunication {
  externalUserId: string;
  userId: string;
  prestacionId: string;
  asunto: string;
  mensaje: string;
  files: any[];
  comunicacionReferenciaId: null | string

}
const RecordDetail: React.FC = (props: any) => {
  const contingencyType = props.location.state?.contingencyType;
  const paramsId = props.match.params.id;
  const arrParamsId = paramsId.split("_");
  const recordType = arrParamsId[0];
  const recordId = arrParamsId[1];
  const [documentID, setDocumentID] = useState<string>("");
  const [economicComponents, setEconomicComponents] = useState([]);
  const [administrativeComponents, setAdministrativeComponents] = useState([]);
  const tokenResponse = useDecode("token");
  const externalUserId = tokenResponse?.extension_external_id;

  const { isImpersonation, isHisam } = useGetRole;

  const {
    collapsed,
    handleOpenModal,
    handleCloseModal,
  } = useModal();
  const {
    collapsed2,
    handleOpenModal2,
    handleCloseModal2,
    handleDisableCloseButton2,
    disabledCloseButton2,
  } = useModals2();
  const {
    getAllTransactionAvailableByContingencyType,
  } = useGetAllTransactionAvailableByContingencyType();
  const {
    getCompleteRecordWithTd,
    getCompleteRecordWithoutTd,
    getCompleteRecordCata,
    getCompleteRecordOficio,
    errorCompleteRecord,
    record,
    userId,
  } = useGetCompleteRecord();
  const {
    getAllHealthcareInformation,
    loadingAllHealthcareInformation,
    dataAllHealthcareInformation,
  } = useGetAllHealthcareInformation();
  const {
    createUserCommunicationRecord,
    successCreateUserCommunicationRecord,
    loadingCreateUserCommunicationRecord,
    errorCreateUserCommunicationRecord,
  } = useCreateUserCommunicationRecord();
  const {
    errorGetUserChatCommunicationRecord,
    getUserChatCommunicationByIdRecord,
    loadingGetUserChatCommunicationRecord,
    userChatCommunicationRecord,
    isComunicacionHabilitada
  } = useGetUserChatCommunicationByIdRecord();
  const {
    allNonAssistanceDocuments,
    getAllNonAssistanceDocuments,
    loadingGetAllNonAssistanceDocuments,
  } = useGetAllNonAssistanceDocuments();
  const { allHealthcareDocuments, getAllHealthcareDocument } =
    useGetAllHealthcareDocument();

  const { getRequestDocumentOtp, errorGetRequestDocumentOtp } =
    useGetRequestDocumentOtp();
  const { getAllAdministrationRecord } =
    useGetAllAdministrationRecord();
  const { getAllEconomicRecord } =
    useGetAllEconomicRecord();
  const { checkContingencyType } = useCheckTypes();
  const { getRoyalDecreeForms, royalDecreeForms, successGetRoyalDecreeForms } = useGetRoyalDecreeForms()
  const breadcrumbs = [
    "Historial",
    checkContingencyType(parseInt(record?.contingencia)),
  ];

  const [collapsedMessage, setCollapsedMessage] = useState(true);

  const handleOpenModalMessage = async () => {
    setCollapsedMessage(false);
  };
  const handleCloseModalMessage = () => {
    setCollapsedMessage(true);
    handleGetCompleteRecord();
  };

  const checkTotalEpisodes = (
    episodes: any[] | undefined,
    episodeType: number
  ) => {
    let result = episodes?.filter(
      (episode) => episode.tipoComponente === episodeType
    );
    if (result && result.length > 0) {
      return result[0].contador;
    }
    return 0;
  };
  const convertBase64 = async (files: any) => {
    if (!files || files.length === 0) return [];
    var arrayAuxiliar: any = [];
    if (files) {
      Array.from(files).forEach((file: any) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          let prevExtension = file.name.split(".");
          var base64I = reader.result as string;
          arrayAuxiliar.push({
            name: file.name,
            extension: prevExtension.pop(),
            externalUri: "",
            base64: base64I.split(",")[1],
            externalId: "",
          });
        };
      });
    }
    return arrayAuxiliar;
  };
  const onSubmitCommunication = async (values: any) => {
    const dataFiles = await convertBase64(values.files || []);
    //send communication
    setTimeout(() => {
      const newDataJsonRecord: IdataComunication = {
        externalUserId: externalUserId,
        userId: userId,
        prestacionId: recordId,
        asunto: values.asunto,
        mensaje: values.mensaje,
        files: dataFiles && dataFiles,
        comunicacionReferenciaId: null
      };

      createUserCommunicationRecord(userId, recordId, newDataJsonRecord);
      handleCloseModal();
    }, 700);

    // redirect
  };
  useEffect(() => {
    getUserChatCommunicationByIdRecord(userId, recordId);
    getAllEconomicRecord(userId, recordId, setEconomicComponents);
    getAllAdministrationRecord(userId, recordId, setAdministrativeComponents);
    getRoyalDecreeForms(userId, recordId)
  }, []);

  useEffect(() => {
    if (
      (successCreateUserCommunicationRecord &&
        !errorCreateUserCommunicationRecord) ||
      (!successCreateUserCommunicationRecord &&
        errorCreateUserCommunicationRecord)
    ) {
      handleCloseModal();
      handleOpenModalMessage();
      getUserChatCommunicationByIdRecord(userId, recordId);
    }
  }, [
    successCreateUserCommunicationRecord,
    errorCreateUserCommunicationRecord,
  ]);

  useEffect(() => {
    if (recordType) {
      handleGetCompleteRecord();
      getAllNonAssistanceDocuments(userId, recordId);
      if (!isImpersonation() || isHisam()) {
        getAllHealthcareDocument(userId, recordId);
      }
    }
    Moment().locale("es").format("L");
  }, [recordId]);

  const getCompleteRecord = (recordType: string) => {
    switch (recordType) {
      case "1":
        return getCompleteRecordWithTd(userId, recordId);
      case "2":
        return getCompleteRecordWithoutTd(userId, recordId);
      case "3":
        return getCompleteRecordCata(userId, recordId);
      case "4":
        return getCompleteRecordOficio(userId, recordId);
    }
  };

  const handleGetCompleteRecord = () => {
    getCompleteRecord(recordType);
    if (!isImpersonation() || isHisam()) {
      getAllHealthcareInformation(userId, recordId);
    }
    if (contingencyType) {
      getAllTransactionAvailableByContingencyType(recordType, contingencyType);
    }
  };

  useEffect(() => {
    if (record && contingencyType === undefined) {
      getAllTransactionAvailableByContingencyType(
        recordType,
        record.contingency_type
      );
    }
  }, [record]);

  const handleRedirect = (pathTo: string, args: any) => {
    if (args != null && args.type != null && args.type_id != null) {
      if (args.type && typeof args.type_id === "number") {
        window.localStorage.setItem("typeEpisode", args.type);
        window.localStorage.setItem("typeId", args.type_id);
        let redirectPath = `/historial/${recordType}_${recordId}_${record.contingencia}/${pathTo}`;
        props.history.push({
          pathname: redirectPath,
          record: record,
          allHealthcareDocuments: allHealthcareDocuments,
          filter: {
            type: args.type,
            type_id: args.type_id,
          },
        });
      }
    } else {
      let redirectPath = `/historial/${recordType}_${recordId}/${pathTo}`;
      props.history.push({
        pathname: redirectPath,
        record: record,
        filter: {
          type: args.type,
        },
      });
    }
  };
  const handleRequestDocument = (documentID: string) => {
    setDocumentID(documentID);
    handleOpenModal2();
    getRequestDocumentOtp(userId, recordId, documentID);
  };

  return (
    <>
      {loadingAllHealthcareInformation || record === null ? (
        <section>
          {loadingAllHealthcareInformation && TextMessagesTemplates.loading}
          {errorCompleteRecord && TextMessagesTemplates.errorResponse}
        </section>
      ) : (
        <>
          <PageMenu
            title={checkContingencyType(parseInt(record.contingencia))}
            type="bottom-box-info"
            breadcrumbs={breadcrumbs}
          >
            <div className="btns-header-container">
              <Link to="/historial">
                <img src={IconArrowGray} alt="icon arrow right" />
              </Link>
            </div>
          </PageMenu>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="box-info-block">
                  {record?.tieneExtincion ? (
                    <div className="record-extinct">
                      <h4 className="description-extincion">{record && record.descripcionExtincion}</h4>
                      <p className="num-expediente">Expediente: {record?.codigoExpediente}</p>
                    </div>
                  ) : (
                    <>
                      <p>Expediente: {record?.codigoExpediente}</p>
                      <p>
                        Fecha Inicio:{" "}
                        {record?.fechaInicio &&
                          `${Moment(record?.fechaInicio).format("L")}`}
                      </p>
                      <p>
                        Fecha Fin:{" "}
                        {record?.fechaFin &&
                          `${Moment(record?.fechaFin).format("L")}`}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
            {(!isImpersonation() || isHisam()) &&
              <section className="row">
                <div className="col-12">
                  <h2 className="section-title mt-4">Información asistencial</h2>
                  <div className="row">
                    <div className="col-lg-4">
                      <CardLinkIcon
                        iconType={`consultation`}
                        title={`Consultas`}
                        numberSessions={checkTotalEpisodes(
                          dataAllHealthcareInformation,
                          200
                        )}
                        typeSessions={`Consultas`}
                        handlerRedirect={handleRedirect}
                        params={{
                          type: "Consultas",
                          type_id: 200,
                        }}
                        pathTo="informacion-asistencial"
                      />
                    </div>
                    <div className="col-lg-4">
                      <CardLinkIcon
                        iconType={`rehab`}
                        title={`Rehabilitación`}
                        numberSessions={checkTotalEpisodes(
                          dataAllHealthcareInformation,
                          205
                        )}
                        typeSessions={`Sesiones`}
                        handlerRedirect={handleRedirect}
                        params={{
                          type: "Rehabilitación",
                          type_id: 205,
                        }}
                        pathTo="informacion-asistencial"
                      />
                    </div>
                    <div className="col-lg-4">
                      <CardLinkIcon
                        iconType={`operation`}
                        title={`Quirófano`}
                        numberSessions={checkTotalEpisodes(
                          dataAllHealthcareInformation,
                          204
                        )}
                        typeSessions={`Operaciónes`}
                        handlerRedirect={handleRedirect}
                        params={{
                          type: "Quirófano",
                          type_id: 204,
                        }}
                        pathTo="informacion-asistencial"
                      />
                    </div>
                    <div className="col-lg-4">
                      <CardLinkIcon
                        iconType={`hospitalization`}
                        title={`Hospitalización`}
                        numberSessions={checkTotalEpisodes(
                          dataAllHealthcareInformation,
                          202
                        )}
                        typeSessions={`Hospitalizaciónes`}
                        handlerRedirect={handleRedirect}
                        params={{
                          type: "Hospitalización",
                          type_id: 202,
                        }}
                        pathTo="informacion-asistencial"
                      />
                    </div>
                    <div className="col-lg-4">
                      <CardLinkIcon
                        iconType={`emergencies`}
                        title={`Urgencias`}
                        numberSessions={checkTotalEpisodes(
                          dataAllHealthcareInformation,
                          206
                        )}
                        typeSessions={`Urgencias`}
                        handlerRedirect={handleRedirect}
                        params={{
                          type: "Urgencias",
                          type_id: 206,
                        }}
                        pathTo="informacion-asistencial"
                      />
                    </div>
                    <div className="col-lg-4">
                      <CardLinkIcon
                        loading={true}
                        iconType={`documentation`}
                        title={`Documentación`}
                        healthcareDocuments={
                          allHealthcareDocuments !== null
                            ? allHealthcareDocuments.length
                            : 0
                        }
                        handlerRedirect={handleRedirect}
                        params={{
                          type: "Documentación asistencial",
                          type_id: 207,
                        }}
                        pathTo="informacion-asistencial"
                      />
                    </div>
                    <div className="col-lg-4">
                      <CardLinkIcon
                        iconType={`image`}
                        title={`Imagen`}
                        numberSessions={checkTotalEpisodes(
                          dataAllHealthcareInformation,
                          203
                        )}
                        typeSessions={`Imagen`}
                        handlerRedirect={handleRedirect}
                        params={{
                          type: "Imagen",
                          type_id: 203,
                        }}
                        pathTo="informacion-asistencial"
                      />
                    </div>
                  </div>
                </div>
              </section>
            }
            <section className="row">
              <div className="col-12">
                <h2 className="section-title">Gestión</h2>
              </div>
              <div className="col-lg-6">
                <div className="management-block">
                  <h3 className="title-icon">
                    <span className="icon calendar"></span>
                    <span className="text">Administración</span>
                  </h3>
                  <p>
                    Información referente a hecho de gestión no considerados
                    asistenciales y económicos.
                  </p>
                  <RecordDetailManagementGrid
                    arrData={administrativeComponents.slice(0, 4)}
                    props={props}
                    typeComponent={"administración"}
                  />
                  <br />
                  <Link
                    className="btn-text"
                    to={`/historial/${recordType}_${recordId}/administracion`}
                  >
                    Ver más
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="management-block">
                  <h3 className="title-icon">
                    <span className="icon economic"></span>
                    <span className="text">Económico</span>
                  </h3>
                  <p>
                    Información económica referente a subsidios, transferencias
                    bancarias, cotizaciones, entre otras.
                  </p>
                  <RecordDetailManagementGrid
                    arrData={economicComponents?.slice(0, 4)}
                    props={props}
                    typeComponent={"económico"}
                  />
                  <br />
                  <Link
                    className="btn-text"
                    to={`/historial/${recordType}_${recordId}/economico`}
                  >
                    Ver más
                  </Link>
                </div>
              </div>
            </section>
            {royalDecreeForms && royalDecreeForms && Array.isArray(royalDecreeForms) && royalDecreeForms.length > 0 && 
              <section className="row">
              <div className="col-12">
                <h2 className="section-title">Formularios</h2>
                <RoyalDecreeFormsGrid arrData2={royalDecreeForms && royalDecreeForms} props={props} />
              </div>
            </section>
            }
            <section className="row">
              <div className="col-12">
                <h2 className="section-title">Documentación General</h2>
                {loadingGetAllNonAssistanceDocuments
                  ? TextMessagesTemplates.loading
                  : ""}
                {allNonAssistanceDocuments?.length ? (
                  // <DocumentsGrid documents={allNonAssistanceDocuments} />
                  <HealthcareDocumentsGrid
                    handleRequestDocument={handleRequestDocument}
                    documents={allNonAssistanceDocuments.map((doc: any) => {
                      return {
                        nombre: doc.nombre,
                        extension: doc.extension,
                        company: doc.company ?? "-",
                        fecha: doc.fecha,
                        id: doc.id,
                        requiereOtp: doc.requiereOtp,
                        uri: doc.uri,
                      };
                    })}
                  />
                ) : (
                  // <p className="mt-4 loader-text">
                  //   No hay documentos para mostrar.
                  // </p>
                  <NoData message={'No hay documentos para mostrar.'} />
                )}
              </div>
              <Modal
                disabledCloseButton={disabledCloseButton2}
                collapsed={collapsed2}
                onClose={handleCloseModal2}
                title={"Introduzca el código"}
                subtitle={
                  "Para visualizar el documento, introduzca el código que hemos enviado a su teléfono"
                }
              >
                {collapsed2 ? (
                  <div className="ghost-form"></div>
                ) : (
                  <>
                    {documentID ? (
                      <FormValidateOTP
                        handleDisableCloseButton={handleDisableCloseButton2}
                        onClose={handleCloseModal2}
                        props={props}
                        documentID={documentID}
                        userId={userId}
                        prestacionId={recordId}
                      />
                    ) : (
                      <>
                        {errorGetRequestDocumentOtp ? (
                          <div>
                            <h3 className="modal_title">Error</h3>
                            <p className="c-center">
                              Lo sentimos, se ha producido un error
                            </p>
                          </div>
                        ) : (

                          <LoaderMin />
                        )}
                      </>
                    )}
                  </>
                )}
              </Modal>
            </section>
            {userChatCommunicationRecord ?
              <section className="row">
                <div className="col-12">
                  <h2 className="section-title">Comentarios</h2>
                </div>
                <>
                  {loadingGetUserChatCommunicationRecord ||
                    errorGetUserChatCommunicationRecord ? (
                    <section>
                      {loadingGetUserChatCommunicationRecord &&
                        TextMessagesTemplates.loading}
                      {errorGetUserChatCommunicationRecord &&
                        TextMessagesTemplates.errorResponse}
                    </section>
                  ) : (
                    ""
                  )}
                  {!userChatCommunicationRecord ? (
                    <div className=" align-items-center">
                      <div className="col-md-12">
                        <section className="container-communication-block">
                          <CommunicationsBlock
                            handleOpenModal={handleOpenModal}
                            handleCloseModal={handleCloseModal}
                            collapsed={collapsed}
                          />
                        </section>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12">
                      <div className="btns-upon-box-container">
                        {isComunicacionHabilitada &&
                          <button
                            className="btn-text btn-gray"
                            onClick={handleOpenModal}
                          >
                            <span className="e-icons e-add-new"></span>
                            <span>Nuevo Comentario</span>
                          </button>
                        }

                      </div>
                      {userChatCommunicationRecord !== undefined ? (
                        <div className="record-detail__communications-grid">
                          <CommunicationsGrid
                            arrData={
                              userChatCommunicationRecord &&
                              userChatCommunicationRecord
                            }
                            props={props}
                            recordType={recordType}
                          />
                        </div>
                      ) : null}
                    </div>
                  )}
                </>
                <Modal collapsed={collapsed} onClose={handleCloseModal}>
                  <>
                    {!collapsed && !isImpersonation() ? (
                      <FormCreateCommunications
                        title={"Crear comunicación"}
                        onSubmit={onSubmitCommunication}
                        onClose={handleCloseModal}
                        loadingProcess={loadingCreateUserCommunicationRecord}
                      />
                    ) : (
                      <div className="ghost-form"></div>
                    )}
                  </>
                </Modal>
                <Modal
                  collapsed={collapsedMessage}
                  onClose={handleCloseModalMessage}
                >
                  <div className="confirm-message">
                    <div className="img-container">
                      <img src={IMGcheck} alt="" />
                    </div>
                    <p>
                      {successCreateUserCommunicationRecord
                        ? "Mensaje enviado. En breve podrá verlo en el listado"
                        : "Lo sentimos, se ha producido un error, inténtelo de nuevo más tarde."}
                    </p>
                    <div className="btn-resolve-container">
                      <button
                        className="btn-box btn-accent"
                        onClick={handleCloseModalMessage}
                      >
                        Entendido
                      </button>
                    </div>
                  </div>
                </Modal>
              </section> : null}
          </div>
        </>
      )}
    </>
  );
};

export default RecordDetail;
