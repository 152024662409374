import React, { useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  PageSettingsModel,
  Sort,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import iconWarnig from'../../../img/icons/ayuda/warning.png'
import iconAnswered from '../../../img/icons/ayuda/charlar2.png'
interface ICommunicationsGrid {
  arrData: any[] | undefined;
  props: any;
  recordType?: number;
}

const CommunicationsGrid: React.FC<ICommunicationsGrid> = ({
  arrData,
  props,
  recordType,
}) => {
  // let tooltip: any;
  // const beforeRender = (args: any) => {
  //   tooltip.content = args.target.closest("td").innerText;
  // };

  const [sortingOptions] = useState<Object>({
    columns: [{ field: "created", direction: "Descending" }],
  });
  let pageOptions: PageSettingsModel = {
    pageSize: 10,
    pageSizes: ["5", "10", "15", "20", "Todos"],
  };
  // const rowDataBound = (args: any) => {
  //   if (args.data.puedeResponder) {
  //     args.row.style.backgroundColor = 'rgba(6, 182, 188, .13)'; 
  //   }
  // };
  const rowSelected = (args: any) => {
    props.history.push({
      pathname: `/historial/${recordType}_${args.data.prestacionId}_${args.data.id}/comunicacion`,
      subject: "Detalle",
      communicationId: args.data.id,
    });
  };
  let newArrData =
    arrData &&
    arrData.map((item: any) => {
      let archivoAdjunto = false;
      if (item.files.length) {
        archivoAdjunto = true;
     
      }
      return {
        ...item,
        archivoAdjunto: archivoAdjunto,
      };
    });
  return (
    <GridComponent
      dataSource={newArrData && newArrData}
      allowPaging={true}
      allowSorting={true}
      rowSelected={rowSelected}
      pageSettings={pageOptions}
      sortSettings={sortingOptions}
      // enablePersistence={true} 
      // rowDataBound={rowDataBound} 
    >
      <ColumnsDirective>
      <ColumnDirective
          headerText=""
          textAlign="Center"
          width="50"
          template={(props: any) =>
            props.puedeResponder ? (
              <TooltipComponent content="Responder">

              <img src={iconWarnig} alt="Warning Icon" style={{ width: '20px', height: '20px' }} />
            </TooltipComponent>
            ) : props.comunicacionReferenciaId ?  <TooltipComponent content="Respuesta"><img src={iconAnswered} alt="Warning Icon" style={{ width: '20px', height: '20px' }} /></TooltipComponent>  : (
              <span></span>
            )
            
          }
        />
        <ColumnDirective
          field="asunto"
          headerText="Asunto"
          textAlign="Left"
          width="170"
        ></ColumnDirective>
        <ColumnDirective
          field="created"
          headerText="Fecha"
          type="date"
          format="dd/MM/yyyy"
          textAlign="Left"
          width="100"
        ></ColumnDirective>
        <ColumnDirective
          field="mensaje"
          headerText="Mensaje"
          textAlign="Left"
          width="100"
        />
        <ColumnDirective
          headerText="Archivo Adjunto"
          textAlign="Center"
          width="100"
          template={(props:any) =>
            props.archivoAdjunto ? (
              <span className="file-icon"></span>
            ) : (
              <span></span>
            )
          }
        />
       
      </ColumnsDirective>
      <Inject services={[Page, Sort]} />
    </GridComponent>
  );
};
export default CommunicationsGrid;
